import { useContext, useEffect, useState } from "react";
import { Client } from "../resources/Client";
import authAxios from "../auth-axios";
import { AxiosError } from "axios";
import { showNotification } from "@mantine/notifications";
import { X } from "tabler-icons-react";
import { UserContext } from "./useAuth";

export type Filter = {
  isVerified: boolean | undefined,
  sortBy: string,
}

export default function useClients(filter: Filter) {
  const user = useContext(UserContext);
  const [clients, setClients] = useState<Array<Client>>([]);
  const [hasNoMoreData, setHasNoMoreData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadMore = (username?: string) => {
    setIsLoading(true);
    let query = "";
    for (const key in filter) {
      const value = filter[key as keyof Filter];
      if (value != null) {
        query += `${key}=${value}&`;
      }
    }
    query += `limit=30&`;
    if (username) {
      query += `from=${username}`;
    }
    authAxios.get<Array<Client>>(`/clients?${query}`)
      .then((res) => {
        const data = res.data;
        if (data.length === 0) {
          setHasNoMoreData(true);
        } else {
          setHasNoMoreData(false);
        }
        if (username) {
          setClients([...clients, ...data]);
        } else {
          // clear previously loaded data
          setClients(data);
        }
      })
      .catch((err: AxiosError) => {
        setClients([]);
        showNotification({
          title: "Error occurred!",
          message: err.response?.data?.message ?? err.message,
          icon: <X />
        });
      }).finally(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setClients([]);
    loadMore();
  }, [filter, user]); // eslint-disable-line react-hooks/exhaustive-deps

  return { clients, loadMore, isLoading, hasNoMoreData };
}
