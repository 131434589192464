import { FirebaseApp, initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage, ref } from "firebase/storage";
import { createContext, ReactNode } from "react";


const firebaseConfig = {
  apiKey: "AIzaSyBfSsoy_DE4Tp-JgMsmo12bLVL37QiAGmA",
  authDomain: "ultranet-equipment-tracker.firebaseapp.com",
  databaseURL: "https://ultranet-equipment-tracker.firebaseio.com",
  projectId: "ultranet-equipment-tracker",
  storageBucket: "ultranet-equipment-tracker.appspot.com",
  messagingSenderId: "480654927723",
  appId: "1:480654927723:web:6bb7c70d4baf5948fe4e2e",
  measurementId: "G-P91ZZ2YJR8"
};



const app: FirebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const FirebaseContext = createContext(auth);
const _storage = getStorage(app, 'client_record_details');

export const storage = (url: string) => ref(_storage, url);

export const FirebaseProvider = (props: { children: ReactNode }) => {
  return (
    <FirebaseContext.Provider value={auth}>
      {props.children}
    </FirebaseContext.Provider>
  );
};

