import { ActionIcon, Group, Indicator, Input, SharedTextProps, Text, Tooltip } from "@mantine/core";
import React, { InputHTMLAttributes } from "react";
import { InputBaseProps } from "@mantine/core/lib/components/Input/Input";
import { ArrowBack } from "tabler-icons-react";

type EditableTextProps = SharedTextProps & InputBaseProps & Omit<InputHTMLAttributes<HTMLInputElement>, "size"> & {
  editable: boolean,
  text?: string,
  name: string;
  textChange: (field: string, value: string) => void;
  oldValue?: string;
}

export const EditableText = (props: EditableTextProps) => {
  return (
    <>
      {
        props.editable ?
          props.oldValue !== props.text ?
            (
              <Indicator inline size={16} color={"yellow"} sx={{
                zIndex: 0
              }}>
                <Group spacing={0}>
                  <Input
                    {...props}
                    autoFocus
                    value={props.text}
                    onChange={(event: any) => props.textChange(props.name, event.target.value)} />

                  <Tooltip label={"Revert"} withArrow position={"right"}>
                    <ActionIcon
                      size={"xs"}
                      onClick={() => {
                        props.textChange(props.name, props.oldValue!);
                      }}>
                      <ArrowBack size={"xs"} />
                    </ActionIcon>
                  </Tooltip>

                </Group>
              </Indicator>
            ) :
            (
              <Input
                {...props}
                autoFocus
                value={props.text}
                onChange={(event: any) => props.textChange(props.name, event.target.value)} />
            )

          : <Text {...props}>{props.text}</Text>
      }
    </>
  );
};