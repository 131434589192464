import { UserCircle } from "tabler-icons-react";
import { Box, Group, Text, UnstyledButton, useMantineTheme } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";


interface UserButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  name?: string | undefined;
  email?: string | undefined;
}

export const User = ({ email, name }: UserButtonProps) => {
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Group
      sx={{
        borderRadius: theme.radius.sm,
        color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black
      }}>
      <Group>
        <UserCircle size={30} />
        <Box>
          <UnstyledButton onClick={() => navigate("/")}>
            <Text size="sm" weight={500}>{name ?? email}</Text>
            {name && (<Text color="dimmed" size="xs">{email}</Text>)}
          </UnstyledButton>
        </Box>
      </Group>
    </Group>
  );
};
