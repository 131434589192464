import { Badge, Group, Table, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { useEffect, useState } from "react";
import { PageWrapper } from "../components/PageWrapper";
import authAxios from "../auth-axios";
import { showNotification } from "@mantine/notifications";
import { CircleCheck, CircleOff, Edit, X } from "tabler-icons-react";
import { AppProgress } from "../components/AppProgress";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

interface Employee {
  displayName: string;
  email: string;
  isDisabled: boolean;
  role: string;
}

export function EmployeeListPage() {
  const [employees, setEmployees] = useState(null as Array<Employee> | null);
  const navigateTo = useNavigate();

  useEffect(() => {
    authAxios.get("/employees")
      .then((res) => {
        setEmployees(res.data);
      })
      .catch((err: AxiosError) => {
        showNotification({
          title: "Error occurred!",
          message: err.response?.data?.message ?? err.message,
          icon: <X />
        });
      });

  }, []);

  return (
    <PageWrapper title={"Employees List"}>
      {employees == null ?
        <AppProgress /> :
        <Table
          horizontalSpacing="xl"
          verticalSpacing="xs"
          striped highlightOnHover>
          <thead>
          <tr>
            <th><Text weight={700}>Email</Text></th>
            <th><Text weight={700}>Name</Text></th>
            <th><Text weight={700}>Action</Text></th>
          </tr>
          </thead>
          <tbody>
          {
            employees.map((element) => (
              <tr key={element!.email}>
                <td>
                  <Group position={"apart"}>
                    <Group>
                      <Text>{element.email}</Text>
                      <Badge>{element.role}</Badge>
                    </Group>
                    <Tooltip label={element.isDisabled ? "Disabled" : "Enabled"}
                             position={"left"}
                             withArrow>
                      {element.isDisabled ?
                        <CircleOff color={"red"} /> : <CircleCheck color={"green"} />}
                    </Tooltip>
                  </Group>
                </td>
                <td><Text>{element.displayName}</Text></td>
                <td>
                  <UnstyledButton onClick={() => navigateTo(`/employees/edit/${element.email}`)}>
                    <Edit />
                  </UnstyledButton>
                </td>
              </tr>)
            )
          }
          </tbody>
        </Table>
      }
    </PageWrapper>
  );
}
