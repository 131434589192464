import { useEffect, useState } from "react";
import authAxios from "../auth-axios";
import { Client } from "../resources/Client";
import { AxiosError } from "axios";

export default function useSuggestions() {
  const [suggestion, setSuggestion] = useState<Array<{ value: string, label: string }>>([]);

  useEffect(() => {
    authAxios.get<Array<Partial<Client>>>(`/clients/_/brief`)
      .then((res) => {
        setSuggestion(res.data.map((it) => ({
          label: it.name!,
          value: it.username!
        })));
      })
      .catch((err: AxiosError) => {
        setSuggestion([]);
      });
  }, []);

  return { suggestion };
}
