import authAxios from "../auth-axios";
import { Client } from "../resources/Client";
import { AxiosError } from "axios";
import { showNotification } from "@mantine/notifications";
import { X } from "tabler-icons-react";
import { UserContext } from "./useAuth";
import React, { useContext, useEffect, useState } from "react";

export type ClientViewModel = Partial<Client & { shortName: string }>

export type Picture = { url?: string; name: string, description?: string }


export default function useClientDetails(username: string) {
  const [client, setClient] = useState<ClientViewModel>({});
  const [pictures, setPictures] = useState<Array<Picture>>([]);
  const [editable, setEditable] = useState(false);

  const user = useContext(UserContext);

  const fetchClient = () => {
    setEditable(false);
    authAxios.get<Client>(`/clients/${username}`)
      .then((res) => {
        let data = res.data;
        const vm: ClientViewModel = {
          ...data,
          shortName: data.name.split(" ")[0].substring(0, 2)
        };
        setClient(vm);
        setPictures([
          { name: "Photo", url: data.photo },
          { name: "House", url: data.houseDetails.picture },
          { name: "Citizenship", url: data.houseDetails.citizenshipFront },
          { name: "Citizenship (Back)", url: data.houseDetails.citizenshipBack },
          ...data.equipments.map(it => ({ name: it.name, url: it.url, description: it.description }))
        ]);
      })
      .catch((err: AxiosError) => {
        showNotification({
          title: "Error occurred!",
          message: err.response?.data?.message ?? err.message,
          icon: <X />
        });
      });
  };

  const verifyClient = async () => {
    setEditable(false);
    if (!client) {
      return;
    }
    authAxios.patch(`/clients/${username}/verify`, {
      isVerified: !client.isVerified
    }).then(async (res) => {
      await fetchClient();
      showNotification({
        title: "Success",
        message: "Updated.",
        color: "green"
      });
    }).catch((err: AxiosError) => {
      showNotification({
        title: "Error occurred!",
        message: err.response?.data?.message ?? err.message,
        icon: <X />
      });
    });
  };

  const submitChanges = async (value: ClientViewModel) => {
    setEditable(false);
    try {
      const response = await authAxios.put(`/clients/${username}`, {
        ...value
      });
      if (response.status === 200) {
        await fetchClient();
        showNotification({
          title: "Success",
          message: "User updated.",
          color: "green"
        });
      }
    } catch (err: any) {
      if (err as AxiosError) {
        showNotification({
          title: "Error",
          message: err.response?.data?.message ?? err.message,
          color: "red"
        });
      }
    }
  };

  useEffect(() => {
    fetchClient();
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps


  return {
    client,
    pictures,
    fetchClient,
    submitChanges,
    setClient,
    verifyClient,
    editable,
    setEditable
  };
}
