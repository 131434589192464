import { Box, Button, Card, Container, Group, PasswordInput, TextInput, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { signInWithEmailAndPassword } from "firebase/auth";
import { showNotification } from "@mantine/notifications";
import { X } from "tabler-icons-react";
import { useContext } from "react";
import { FirebaseContext } from "../initializeFirebase";

export function LoginPage() {
  const auth = useContext(FirebaseContext);

  const form = useForm({
    initialValues: {
      email: "",
      password: ""
    },
    validate: {}
  });


  const handleLogin = async (values: any) => {
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
    } catch (err: any) {
      showNotification({
        title: "Error",
        color: "red",
        message: err.response?.data?.message ?? err.message,
        icon: (<X size={18} />)
      });
    }
  };

  return (
    <Container size={400}>
      <Box p="xl">
        <Card
          shadow="sm" px="25px" py="55px"
          sx={{
            border: "1px solid"
          }}>

          <Card.Section>
            <Title order={4} align={"center"}>Login to Portal</Title>
          </Card.Section>

          <Card.Section p="lg">
            <form onSubmit={form.onSubmit(handleLogin)}>
              <TextInput
                label="Email"
                placeholder="email@admin.com"
                {...form.getInputProps("email")}
              />

              <PasswordInput
                mt="sm"
                label="Password"
                {...form.getInputProps("password")}
              />

              <Group position="center" mt="md">
                <Button type="submit" fullWidth>Submit</Button>
              </Group>
            </form>

          </Card.Section>
        </Card>
      </Box>
    </Container>
  );
}
