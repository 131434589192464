export const passwordRequirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" }
];

export const colors = ["dark", "red", "pink", "grape", "violet",
  "indigo", "blue", "cyan", "green", "lime", "yellow", "orange", "teal"];

export const randomColor = () => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export const deepCompare = (arg1: { [key: string]: any }, arg2: { [key: string]: any }, result: { [key: string]: any }) => {
  arg1 ??= {};
  arg2 ??= {};
  const keys1 = Object.keys(arg1);
  const keys2 = Object.keys(arg2);
  const maxLength = Math.max(keys2.length, keys1.length);
  const keys = keys1.length > keys2.length ? keys1 : keys2;
  for (let i = 0; i < maxLength; i++) {
    const key = keys[i];
    if (Array.isArray(arg1[key]) && Array.isArray(arg2[key])) {
      if (result[key] == null) {
        result[key] = [];
      }
      for (let index = 0; index < Math.max(arg1?.[key]?.length ?? 0, arg2?.[key]?.length ?? 0); index++) {
        result[key].push({});
        deepCompare(arg1?.[key]?.[index], arg2?.[key]?.[index], result[key][index]);
      }
      if (result[key].every((r: any) => Object.keys(r).length === 0)) {
        delete result[key];
      }
    } else if (typeof arg1[key] === "object" && typeof arg2[key] === "object") {
      result[key] = {};
      deepCompare(arg1[key], arg2[key], result[key]);
      if (Object.keys(result[key]).length === 0) {
        delete result[key];
      }
    } else if (arg1[key] !== arg2[key]) {
      result[key] = arg2[key];
    }
  }
  return result;
};