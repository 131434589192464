import { Box, Button, Checkbox, Container, Group, Select, Space, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { At, Hash, IdBadge, Lock, X } from "tabler-icons-react";
import { PageWrapper } from "../components/PageWrapper";
import { PasswordTextField } from "../components/PasswordTextField";
import authAxios from "../auth-axios";
import { AxiosError } from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { passwordRequirements } from "../utils";

export function CreateEmployeePage() {
  const { email } = useParams();
  const navigateTo = useNavigate();

  useEffect(() => {
    if (email != null) {
      authAxios.get(`/employees/${email}`)
        .then(res => {
          form.setValues(res.data);
        }).catch((err: AxiosError) => {
        showNotification({
          title: "Error occurred!",
          message: err.response?.data?.message ?? err.message,
          icon: <X />
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const form = useForm({
    initialValues: {
      displayName: "",
      email: "",
      password: "",
      role: "",
      isDisabled: false
    },
    validate: {
      password: (value) => {
        if(email) return null;
        return !passwordRequirements.every(requirement => requirement.re.test(value))
          ? "Passwords did not meet requirement"
          : null;
      }
    }
  });

  const handleSignup = () => {
    const values = form.values;
    console.log(form.errors)
    if (form.validate().hasErrors) {
      showNotification({
        title: "Error",
        message: "Form has errors",
        color: "red"
      });
      return;
    }
    const request = email ?
      authAxios.put(`/employees/${email}`, {
        displayName: values.displayName,
        role: values.role,
        isDisabled: values.isDisabled
      }) :
      authAxios.post("/employees", {
        ...values
      });

    request.then((_) => {
      showNotification({
        title: "Success",
        message: !email ? "User created." : "User updated.",
        color: "green"
      });
      navigateTo("/employees");
    }).catch((err: AxiosError) => {
      showNotification({
        title: "Error",
        message: err.response?.data?.message ?? err.message,
        color: "red"
      });
    });
  };

  return (
    <PageWrapper title={!email ? "Add New Portal Employee" : "Edit Employee"}>
      <Container size={500}>
        <Box p={5} mt={"lg"}>
          <form>
            <TextInput
              placeholder="Your name"
              label="Full name"
              icon={<IdBadge size={14} />}
              radius="xl"
              size="md"
              required
              autoComplete="off"
              {...form.getInputProps("displayName")}
            />
            <Space h={5} />
            <TextInput
              placeholder="email@email.com"
              label="Email"
              icon={<At size={14} />}
              radius="xl"
              disabled={email != null}
              size="md"
              autoComplete="off"
              required
              {...form.getInputProps("email")}
            />

            <Space h={5} />

            {!email && (<>
                <PasswordTextField
                  placeholder="Password"
                  label="Password"
                  icon={<Lock size={14} />}
                  autoComplete="off"
                  radius="xl"
                  size="md"
                  required
                  {...form.getInputProps("password")}
                />
                <Space h={5} />
              </>
            )
            }
            <Select
              label="User Role"
              placeholder="Pick a role"
              radius="xl"
              size="md"
              data={[
                { value: "ADMIN", label: "Administrator" },
                { value: "USER", label: "User" }
              ]}
              icon={<Hash size={14} />}
              {...form.getInputProps("role")}
            />
            <Space h={10} />

            {email && (
              <>
                <Checkbox
                  label="Disable?"
                  {...form.getInputProps("isDisabled")}
                />
              </>
            )}

            <Group position="right" mt="md">
              <Button onClick={handleSignup}>{!email ? "Submit" : "Update"}</Button>
            </Group>
          </form>
        </Box>
      </Container>

    </PageWrapper>
  );
}
