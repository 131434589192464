import {
  Anchor,
  Box,
  Center,
  Grid,
  Group,
  Loader,
  SegmentedControl,
  Select,
  Table,
  Text,
  Tooltip
} from "@mantine/core";
import { useCallback, useState } from "react";
import { CircleCheck, Search, UserOff } from "tabler-icons-react";
import { PageWrapper } from "../components/PageWrapper";
import InfiniteScroll from "react-infinite-scroll-component";
import useClients from "../hooks/useClients";
import useSuggestions from "../hooks/useSuggestions";


const clientVerificationStatus: Array<{ value: string, label: string }> = [
  { value: "All", label: "All" },
  { value: "Verified", label: "Verified" },
  { value: "Unverified", label: "Unverified" }
];
const sortOrders: Array<{ value: string, label: string }> = [
  { value: "Desc", label: "Desc" },
  { value: "Asc", label: "Asc" }
];

type Filter = {
  isVerified: boolean | undefined,
  sortBy: string,
  sortOrder: "Asc" | "Desc"
}

export function ClientListPage() {
  const [filter, setFilter] = useState<Filter>({
    isVerified: undefined,
    sortBy: "collectedOn",
    sortOrder: "Desc"
  });
  const { clients, loadMore, hasNoMoreData } = useClients(filter);
  const { suggestion } = useSuggestions();
  const openUser = useCallback((username: string | null) => {
    username && window.open(`${window.location.origin}/clients/${username}`, "_blank");
  }, []);

  return (
    <PageWrapper title={"Client List"}>
      <Box py={"lg"} mx={0}>
        <Grid justify="center" align="center">
          <Grid.Col sm={4} xs={12}>
            <Select
              placeholder="Jump to user..."
              onChange={(username) => openUser(username)}
              searchable
              radius="xl"
              size="xs"
              icon={<Search />}
              nothingFound="No user"
              data={suggestion}
            />
          </Grid.Col>
          <Grid.Col sm={8} xs={12}>
            <Grid justify="center" align="center">
              <Grid.Col md={4} xs={12}>
                <SegmentedControl
                  radius="xs"
                  size="xs"
                  data={clientVerificationStatus}
                  onChange={(value) => {
                    let isVerified: boolean | undefined;
                    if (value === "All") {
                      isVerified = undefined;
                    } else if (value === "Verified") {
                      isVerified = true;
                    } else {
                      isVerified = false;
                    }
                    setFilter({
                      ...filter,
                      isVerified: isVerified
                    });
                  }
                  }
                />
              </Grid.Col>
              <Grid.Col md={4} xs={6}>
                <Select
                  size="xs"
                  placeholder="Sort By"
                  data={[
                    { value: "collectedOn", label: "Created On" },
                    { value: "customerUsername", label: "Username" },
                    { value: "customerName", label: "Name" }
                  ]}
                  onChange={(value) => {
                    setFilter({
                      ...filter,
                      sortBy: value || "collectedOn"
                    });
                  }}
                />
              </Grid.Col>
              <Grid.Col md={4} xs={6}>
                <SegmentedControl
                  radius="xs"
                  size="xs"
                  data={sortOrders}
                  onChange={(value) => {
                    let sortOrder: Filter["sortOrder"];
                    if (value === "Asc") {
                      sortOrder = "Asc";
                    } else if (value === "Desc") {
                      sortOrder = "Desc";
                    } else {
                      sortOrder = "Desc";
                    }
                    setFilter({
                      ...filter,
                      sortOrder: sortOrder
                    });
                  }
                  }
                />
              </Grid.Col>
            </Grid>
          </Grid.Col>
        </Grid>
        {clients?.length > 0 ?
          <InfiniteScroll
            next={() => loadMore(clients[clients.length - 1].username)}
            hasMore={!hasNoMoreData} dataLength={clients.length}
            loader={<Center pt={20}><Loader variant="dots" /></Center>}>
            <Table
              horizontalSpacing="xl"
              verticalSpacing="xs"
              striped highlightOnHover>
              <thead>
              <tr>
                <th><Text size={"xs"} weight={600}>Name</Text></th>
                <th><Text size={"xs"} weight={600}>Collected On</Text></th>
                <th><Text size={"xs"} weight={600}>Address</Text></th>
                <th><Text size={"xs"} weight={600}>Contact</Text></th>
              </tr>
              </thead>
              <tbody>
              {clients.map((element) => (
                <tr key={element!.username}>
                  <td>
                    <Group>
                      <Anchor target="_blank" href={`/clients/${element.username}`}>
                        <Text size={"sm"}>
                          {element.name}
                        </Text>
                      </Anchor>
                    </Group>
                    <Group align="center">
                      <Text weight={400} size={"xs"}>{element.username}</Text>
                      <Tooltip
                        label={element.isVerified ? "Verified" : "Unverified"}
                        position={"right"}
                        withArrow>
                        {element.isVerified
                          ? <CircleCheck size={16} color={"green"} />
                          : <UserOff size={16} color={"red"} />}
                      </Tooltip>
                    </Group>
                  </td>
                  <td>
                    <Text weight={400} size={"xs"}>{element.collectedOn}</Text>
                    <Text weight={400} size={"xs"}>{element.collectedBy}</Text>
                  </td>
                  <td><Text>{element.houseDetails.address}</Text></td>
                  <td><Text>{element.contact}</Text></td>
                </tr>)
              )}
              </tbody>
            </Table>
          </InfiniteScroll> : <></>
        }
      </Box>
    </PageWrapper>
  );
}
