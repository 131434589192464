import { Avatar, Box, Button, Center, Container, Group, Space, Table, Text } from "@mantine/core";
import { PageWrapper } from "../components/PageWrapper";
import { randomColor } from "../utils";
import { UserContext } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { FirebaseContext } from "../initializeFirebase";

export function ProfilePage() {
  const user = useContext(UserContext);
  const auth = useContext(FirebaseContext);
  const navigateTo = useNavigate();

  return (
    <PageWrapper>
      <Box p={10} m={"lg"}>
        <Center>
          <Avatar color={randomColor()} radius="xl" size={"xl"}>
            {user?.displayName?.substring(0, 2)?.toUpperCase()}
          </Avatar>
        </Center>
        <Space h={"xl"} />
        <Container>
          <Group position="center">
            <Text size={"xl"} weight={600}>{user?.displayName}</Text>
          </Group>
          <Group position="center">
            <Text size={"md"} weight={400}>{user?.email}</Text>
          </Group>

          <Space h={50} />
          <Table verticalSpacing="xs" fontSize="xs">
            <thead>
            <tr>
              <th>Created at</th>
              <th>Last login</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>{user?.metadata?.creationTime}</td>
              <td>{user?.metadata?.lastSignInTime}</td>
            </tr>
            </tbody>
          </Table>
          <Space h={50} />
          <Group position="center" mt="md">
            <Button color="red" onClick={() => {
              auth.signOut().then(() => {
                navigateTo("/login");
              });
            }}>Logout</Button>
          </Group>
        </Container>
      </Box>
    </PageWrapper>
  );
}
