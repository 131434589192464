import { Badge, Burger, Group, Header, MediaQuery, Menu, Text, useMantineTheme } from "@mantine/core";
import { User } from "./UserComponent";
import { EyeCheck, Logout } from "tabler-icons-react";
import React, { useContext } from "react";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { VERSION } from "../version";
import { UserContext } from "../hooks/useAuth";
import { FirebaseContext } from "../initializeFirebase";

interface AppHeaderProps {
  navBarState: boolean;
  navOpenHandler: (open: boolean) => void;
}

interface ProfileMenuItemProps {
  onChangePassword: () => void,
  onLogout: () => void
}

function ProfileMenuItem(props: ProfileMenuItemProps) {
  return <Menu withArrow placement="center">
    <Menu.Item
      icon={<EyeCheck size={14} />}
      onClick={() => {
        props.onChangePassword();
      }}>
      Change password
    </Menu.Item>
    <Menu.Item
      color="red"
      icon={<Logout size={14} />}
      onClick={() => {
        props.onLogout();
      }
      }>
      Sign out
    </Menu.Item>
  </Menu>;
}

export const AppHeader = (props: AppHeaderProps) => {
  const auth = useContext(FirebaseContext);
  const user = useContext(UserContext);

  const theme = useMantineTheme();
  const navigateTo = useNavigate();

  const handleLogout = async () => {
    await signOut(auth);
    navigateTo("/login");
  };

  return (
    <Header height={70} p="xl">
      <Group position="apart">
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Burger
            opened={props.navBarState}
            onClick={() => {
              props.navOpenHandler(!props.navBarState);
            }}
            size="sm"
            color={theme.colors.gray[6]}
            mr="xl"
          />
        </MediaQuery>

        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Group>
            <Text variant="gradient" weight={"bolder"}
                  size={"lg"}
                  gradient={{ from: "blue", to: "orange" }}>
              Ultranet Admin Panel
            </Text>
            <Badge
              size={"xs"}
              color={"orange"} variant={"filled"}
              sx={{ textTransform: "lowercase" }}>
              {VERSION.toLowerCase()}
            </Badge>
          </Group>
        </MediaQuery>

        {user && (
          <>
            <Group position={"right"}>
              <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
                <Group>
                  <User
                    name={user.displayName || undefined}
                    email={user.email || undefined} />
                </Group>
              </MediaQuery>
              <ProfileMenuItem
                onChangePassword={() => navigateTo("/changePassword")}
                onLogout={() => handleLogout()}
              />
            </Group>
          </>
        )}
      </Group>
    </Header>
  );
};
