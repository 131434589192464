import "./App.css";
import { AppShell, Navbar, useMantineTheme } from "@mantine/core";
import React, { useContext, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { AppHeader } from "./components/AppHeader";
import { UserContext } from "./hooks/useAuth";
import { AppNavLinks } from "./components/AppNavbarLinks";
import { EmployeeListPage } from "./pages/EmployeeListPage";
import { CreateEmployeePage } from "./pages/CreateEmployeePage";
import { ClientListPage } from "./pages/ClientListPage";
import { ClientDetailPage } from "./pages/ClientDetailPage";
import { ChangePasswordPage } from "./pages/ChangePasswordPage";
import { ProfilePage } from "./pages/ProfilePage";

function App() {
  const user = useContext(UserContext);
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);

  return (
    <AppShell
      styles={{
        main: {
          background: theme.colors.gray[0]
        }
      }}
      navbarOffsetBreakpoint="sm"
      asideOffsetBreakpoint="sm"
      fixed
      padding="md"
      navbar={
        user && (
          <Navbar
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 230 }}>
            <Navbar p="sm" width={{ base: 230 }}>
              <Navbar.Section grow mt="md">
                <AppNavLinks />
              </Navbar.Section>
            </Navbar>
          </Navbar>)
      }
      header={
        <AppHeader
          navOpenHandler={setOpened}
          navBarState={opened} />
      }>

      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/employees" element={<EmployeeListPage />} />
        <Route path="/changePassword" element={<ChangePasswordPage />} />
        <Route path="/" element={<ProfilePage />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/employees">
          <Route path="create" element={<CreateEmployeePage />} />
          <Route path="edit/:email" element={<CreateEmployeePage />} />
        </Route>
        <Route path="/clients" element={<ClientListPage />} />
        <Route path="/clients/:username" element={<ClientDetailPage />} />
      </Routes>
    </AppShell>
  );
}

export default App;
