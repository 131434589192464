import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  AlertCircle,
  Checklist,
  CircleCheck,
  CircleDashed,
  IdBadge2,
  Location,
  Notes,
  Pencil,
  PhoneCall,
  UserOff,
  X
} from "tabler-icons-react";
import { PageWrapper } from "../components/PageWrapper";
import { AppProgress } from "../components/AppProgress";
import {
  Alert,
  Anchor,
  Avatar,
  Button,
  Container,
  Group,
  Image,
  List,
  Menu,
  Modal,
  Space,
  Text,
  ThemeIcon,
  Tooltip,
  useMantineTheme
} from "@mantine/core";
import { storage } from "../initializeFirebase";
import { getDownloadURL } from "firebase/storage";
import { Client } from "../resources/Client";
import { EditableText } from "../components/EditableText";
import { ChangeList } from "../components/ChangeList";
import useClientDetails, { ClientViewModel, Picture } from "../hooks/useClientDetails";

const resolveUrl = (url: string = "", cb: (uri: string) => void) => {
  if (!url) cb("");
  else {
    getDownloadURL(storage(url))
      .then((downloadURL) => {
        cb(downloadURL);
      })
      .catch((er) => {
        cb(url);
      });
  }
};

interface PictureListProps {
  pictures: Array<Picture>;
}

type ClientMenuProps = {
  client: ClientViewModel;
  onVerifiedChangeClick: () => void;
  onEditClick: () => void;
}

function PictureList({ pictures }: PictureListProps) {
  const [selectedPicture, setSelectedPicture] = useState<{ url?: string, name: string } | null>(null);

  return (
    <Group>
      <Space w={15} />
      <Text color="dimmed">Pictures</Text>
      <List
        spacing="xs"
        size="sm"
        center
        icon={<></>}>
        {
          pictures.map((picture) => (
            <List.Item>
              <Group>
                {picture.url == null
                  ? (
                    <ThemeIcon color="blue" size={24} radius="xl">
                      <CircleDashed size={16} />
                    </ThemeIcon>)
                  : (
                    <ThemeIcon color="teal" size={24} radius="xl">
                      <CircleCheck size={16} />
                    </ThemeIcon>
                  )
                }
                <Text ml={"xs"}>
                  <Anchor
                    onClick={() => {
                      resolveUrl(picture.url, (url) => {
                        setSelectedPicture({
                          name: picture.name,
                          url: url
                        });
                      });
                    }}>
                    {picture.name}
                  </Anchor>
                  {
                    (picture.description?.length! > 0)
                    && <Text inherit component="span" size={"xs"}
                             color="dimmed">({picture.description || "-"})</Text>
                  }

                </Text>
              </Group>

            </List.Item>
          ))
        }
      </List>

      <Modal
        overflow={"inside"}
        opened={selectedPicture != null}
        onClose={() => setSelectedPicture(null)}
        title={selectedPicture?.name ?? ""}>
        <Image
          sx={{
            maxHeight: 500,
            maxWidth: 500
          }}
          radius="md"
          src={selectedPicture?.url ?? ""}
        />
      </Modal>
    </Group>
  );
}

export function ClientDetailPage() {
  const [oldValue, setOldValue] = useState<ClientViewModel>({});
  const { username } = useParams();
  const theme = useMantineTheme();

  const {
    client, pictures, submitChanges, setClient, verifyClient, editable, setEditable
  } = useClientDetails(username!);

  return (
    <PageWrapper>
      {
        Object.keys(client).length === 0 ?
          <AppProgress /> :
          <Container m={20}>
            <Group>
              <Avatar color={theme.colors.red[1]} radius="xl">{client.shortName}</Avatar>
              <EditableText
                name={"name"} size={"sm"}
                placeholder={"Full Name"}
                oldValue={oldValue?.name}
                text={client.name} editable={editable}
                textChange={(field, value) => {
                  setClient({
                    ...client,
                    [field]: value.toUpperCase()
                  });
                }} />

              <Tooltip
                label={client.isVerified ? "Verified" : "Unverified"}
                position={"right"}
                withArrow>
                {client.isVerified
                  ? <CircleCheck color={"green"} />
                  : <UserOff color={"red"} />}
              </Tooltip>

              <Space w={"lg"} />
              <ClientMenu
                client={client}
                onVerifiedChangeClick={verifyClient}
                onEditClick={() => {
                  setEditable(true);
                  setOldValue(JSON.parse(JSON.stringify(client)) as Client);
                }}
              />
            </Group>
            <Space h={"sm"} />

            <Group ml={15}>
              <IdBadge2 />
              <Text size={"md"} weight={600}>{client.username} </Text>
            </Group>

            <Space h={"sm"} />
            <Group ml={15}>
              <PhoneCall />
              <EditableText
                name={"contact"} size={"sm"}
                editable={editable}
                text={client.contact}
                oldValue={oldValue?.contact}
                placeholder={"Contact Number"}
                textChange={(field, value) => {
                  setClient({
                    ...client,
                    [field]: value.toUpperCase()
                  });
                }} />

            </Group>

            {/* Address */}
            <Space h={"sm"} />
            <Group ml={15}>
              <Location />
              <EditableText
                name={"address"}
                size={"md"}
                weight={500}
                placeholder={"Address"}
                oldValue={oldValue?.houseDetails?.address}
                text={client.houseDetails?.address}
                editable={editable}
                textChange={(field, value) => {
                  const houseDetails = {
                    ...client.houseDetails,
                    [field]: value
                  };
                  setClient({
                    ...client,
                    houseDetails: houseDetails
                  });
                }}
              />

              <EditableText
                name={"description"}
                size={"md"}
                color="dimmed"
                placeholder={"House Description"}
                text={client.houseDetails?.description}
                oldValue={oldValue?.houseDetails?.description}
                editable={editable}
                textChange={(field, value) => {
                  const houseDetails = {
                    ...client.houseDetails,
                    [field]: value
                  };
                  setClient({
                    ...client,
                    houseDetails: houseDetails
                  });
                }} />
              <EditableText
                name={"latLng"}
                size={"md"}
                color="dimmed"
                placeholder={"GPS Location"}
                text={client.houseDetails?.latLng}
                oldValue={oldValue?.houseDetails?.latLng}
                editable={editable}
                textChange={(field, value) => {
                  const houseDetails = {
                    ...client.houseDetails,
                    [field]: value
                  };
                  setClient({
                    ...client,
                    houseDetails: houseDetails
                  });
                }} />
            </Group>

            <Space h={"sm"} />
            <Group ml={15}>
              <Notes />
              <Text size={"xs"} color="dimmed">
                Collect on {client.collectedOn || "_"} by {client.collectedBy || "_"}
              </Text>
            </Group>

            <Space h={"sm"} />
            <PictureList pictures={pictures} />


            <Space h={"lg"} />
            <Space h={"lg"} />
            {editable && <>
              <ChangeList oldJson={oldValue} newJson={client} />
              <Space h={4} />

              <Alert icon={<AlertCircle size={16} />} title="Warning" color="yellow">
                Old values will be overridden with the new one.
              </Alert>

              <Space h={"lg"} />
              <Group position={"center"}>
                <Button
                  onClick={() => {
                    submitChanges({ ...client });
                  }}>
                  Save
                </Button>
                <Button
                  color={"red"}
                  onClick={() => {
                    setEditable(false);
                  }}>
                  Cancel
                </Button>
              </Group>


            </>}

          </Container>
      }
    </PageWrapper>
  );
}

const ClientMenu = ({ client, onVerifiedChangeClick, onEditClick }: ClientMenuProps) => {
  return (
    <Menu withArrow placement="end" size={"lg"} position={"right"}>
      <Menu.Item
        color={client.isVerified ? "red" : "green"}
        icon={client.isVerified ? <X size={15} /> : <Checklist size={14} />}
        onClick={onVerifiedChangeClick}>
        {
          client.isVerified ? "Un-verify" : "Set as verified"
        }
      </Menu.Item>

      <Menu.Item
        icon={<Pencil size={14} />}
        onClick={onEditClick}>Edit</Menu.Item>
    </Menu>
  );
};
