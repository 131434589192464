import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import { BrowserRouter } from "react-router-dom";
import { NotificationsProvider } from "@mantine/notifications";
import { FirebaseProvider } from "./initializeFirebase";
import { UserProvider } from "./hooks/useAuth";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <FirebaseProvider>
    <MantineProvider>
      <NotificationsProvider>
        <BrowserRouter>
          <UserProvider>
            <App />
          </UserProvider>
        </BrowserRouter>
      </NotificationsProvider>
    </MantineProvider>
  </FirebaseProvider>
);
