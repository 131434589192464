import { At, ListDetails, UserPlus, Users } from "tabler-icons-react";
import { Box, Divider, Group, Text, ThemeIcon, UnstyledButton } from "@mantine/core";
import * as React from "react";
import { useNavigate } from "react-router-dom";

interface NavbarLinkProps {
  icon: React.ReactNode;
  color: string;
  label: string;
  path: string;
}

function NavbarLink({ icon, color, label, path }: NavbarLinkProps) {
  const navigateTo = useNavigate();
  return (
    <UnstyledButton
      onClick={() => navigateTo(path)}
      sx={(theme) => ({
        display: "block",
        width: "100%",
        padding: theme.spacing.xs,
        borderRadius: theme.radius.sm,
        color: theme.black,

        "&:hover": {
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[6]
              : theme.colors.gray[0]
        }
      })}
    >
      <Group>
        <ThemeIcon color={color} variant="light">
          {icon}
        </ThemeIcon>

        <Text size="sm">{label}</Text>
      </Group>
    </UnstyledButton>
  );
}

const data: Array<string | NavbarLinkProps> = [
  {
    icon: <At size={16} />, color: "green",
    label: "Profile",
    path: "/"
  },
  {
    icon: <ListDetails size={16} />, color: "teal",
    label: "View Client Records",
    path: "/clients"
  },
  "Employees",
  {
    icon: <UserPlus size={16} />, color: "blue",
    label: "Add new employee",
    path: "/employees/create"
  },
  {
    icon: <Users size={16} />, color: "yellow",
    label: "View employees",
    path: "/employees"
  },
  ""
];

export function AppNavLinks() {
  const links = data.map((link) => {
    if (typeof link == "string") {
      return (<Box my={"sm"} key={link}>
        <Divider label={link.toUpperCase()} labelPosition={"center"} />
      </Box>);
    } else {
      return (<NavbarLink {...link} key={link.label} />);
    }
  });
  return <div>{links}</div>;
}
