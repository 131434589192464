import { Box, Center, Loader } from "@mantine/core";
import React from "react";

export const AppProgress = () => {
  return (
    <Box p={50}>
      <Center>
        <Loader variant="dots" />
      </Center>
    </Box>
  );
};