import axios from "axios";
import { auth } from "./initializeFirebase";


const defaultOptions = {
  baseURL: process.env.BASE_URL || "/api/",
  headers: {
    "Content-Type": "application/json"
  }
};
const authAxios = axios.create(defaultOptions);


authAxios.interceptors.request.use((config) => {
  return auth.currentUser?.getIdTokenResult()
    .then((jwt) => {
      config.headers = {
        ...config.headers,
        Authorization: jwt.token
      };
      return config;
    })
    .catch((e) => {
      return config;
    });

});

export default authAxios;
