import { Box, Button, Container, Group, Space } from "@mantine/core";
import { useEffect } from "react";
import { showNotification } from "@mantine/notifications";
import { useForm } from "@mantine/form";
import { Lock } from "tabler-icons-react";
import { PageWrapper } from "../components/PageWrapper";
import { PasswordTextField } from "../components/PasswordTextField";
import authAxios from "../auth-axios";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import { passwordRequirements } from "../utils";

export function ChangePasswordPage() {
  const navigateTo = useNavigate();

  const form = useForm({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    validate: {
      password: (value, values) => {
        if (value !== values.confirmPassword) {
          return "Password doesn't match.";
        }
        return !passwordRequirements.every(requirement => requirement.re.test(value))
          ? "Passwords did not meet requirement"
          : null;
      }
    }
  });

  useEffect(() => {
    form.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: any) => {
    const request = authAxios.patch(`/changePassword`, {
      password: values.password
    });

    request.then((_) => {
      showNotification({
        title: "Success",
        message: "Password changed.",
        color: "green"
      });
      navigateTo("/employees");
    }).catch((err: AxiosError) => {
      showNotification({
        title: "Error",
        message: err.response?.data?.message ?? err.message,
        color: "red"
      });
    });
  };

  return (
    <PageWrapper title={"Change your password"}>
      <Container size={500}>
        <Box p={5} mt={"lg"}>
          <form onSubmit={form.onSubmit(handleSubmit)}>

            <PasswordTextField
              placeholder="Enter new password"
              label="New password"
              icon={<Lock size={14} />}
              autoComplete="off"
              radius="xl"
              size="md"
              required
              {...form.getInputProps("password")}
            />

            <Space h={25} />
            <PasswordTextField
              placeholder="Confirm password"
              label="Confirm password"
              icon={<Lock size={14} />}
              autoComplete="off"
              radius="xl"
              size="md"
              required
              {...form.getInputProps("confirmPassword")}
            />
            <Space h={5} />


            <Group position="right" mt="md">
              <Button type="submit">Update</Button>
            </Group>
          </form>
        </Box>
      </Container>

    </PageWrapper>
  );
}
