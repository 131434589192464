import { Center, Grid, JsonInput, Text } from "@mantine/core";
import { deepCompare } from "../utils";


type ChangeListProps = {
  oldJson: Record<string, any>,
  newJson: Record<string, any>
}


export function ChangeList(props: ChangeListProps) {
  const leftDiff = JSON.stringify(deepCompare(props.newJson, props.oldJson, {}), null, 4);
  const rightDiff = JSON.stringify(deepCompare(props.oldJson, props.newJson, {}), null, 4);

  return (
    <>
      {
        (leftDiff !== "{}" && rightDiff !== "{}") ?
          <Grid>
            <Grid.Col sm={12} md={6} lg={6}>
              <Center><Text size={"sm"}>Old Value</Text></Center>
              <JsonInput
                autosize
                value={leftDiff}>
              </JsonInput>
            </Grid.Col>
            <Grid.Col sm={12} md={6} lg={6}>
              <Center><Text size={"sm"}>New changes</Text></Center>
              <JsonInput
                autosize
                value={rightDiff}>
              </JsonInput>
            </Grid.Col>
          </Grid> : <></>
      }
    </>

  );
}