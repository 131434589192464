import { Auth, onAuthStateChanged, User } from "firebase/auth";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "../initializeFirebase";

export const UserContext = createContext<User | undefined | null>(undefined);

export const UserProvider = (props: { children: ReactNode }) => {
  const { user } = useAuth();
  return (
    <UserContext.Provider value={user}>
      {props.children}
    </UserContext.Provider>
  );
};


function useAuth(): { user?: User | null, auth: Auth } {
  const auth = useContext(FirebaseContext);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (res) => {
      const currentPathName = window.location.pathname;
      if (res) {
        setUser(res);
        if (currentPathName === "/" || currentPathName === "/login") {
          window.location.href = "/profile";
        }
      } else {
        setUser(undefined);
        if (currentPathName !== "/login") {
          window.location.href = "/login";
        }
      }
    });

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    user,
    auth
  };
}
