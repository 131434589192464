import { Box, Card, Title } from "@mantine/core";
import { ReactNode } from "react";

interface PageWrapperProps {
  title?: string;
  children: ReactNode;
}

export const PageWrapper = ({ title, children }: PageWrapperProps) => {
  return (
    <Card>
      {
        title && (<Card.Section p={"lg"}>
          <Title order={4}>{title}</Title>
        </Card.Section>)
      }
      <Card.Section px={"lg"}>
        <Box sx={(theme) => ({
          border: "1px solid",
          borderColor: theme.colors.gray[2]
        })}>
          {children}
        </Box>
      </Card.Section>

    </Card>
  );
};
