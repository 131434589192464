import { useState } from "react";
import { Box, PasswordInput, Popover, Progress, Text } from "@mantine/core";
import { Check, Cross } from "tabler-icons-react";
import { PasswordInputProps } from "@mantine/core/lib/components/PasswordInput/PasswordInput";
import { GetInputPropsPayload } from "@mantine/form/lib/types";
import { passwordRequirements } from "../utils";

function PasswordRequirement({ meets, label }: { meets: boolean; label: string }) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <Check /> : <Cross />} <Box ml={10}>{label}</Box>
    </Text>
  );
}


function getStrength(password: string) {
  let multiplier = password.length > 5 ? 0 : 1;

  passwordRequirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (passwordRequirements.length + 1)) * multiplier, 10);
}

export function PasswordTextField(props: PasswordInputProps & GetInputPropsPayload) {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState("");
  const checks = passwordRequirements.map((requirement, index) => (
    <PasswordRequirement key={index} label={requirement.label} meets={requirement.re.test(value)} />
  ));

  const strength = getStrength(value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  return (
    <Box>
      <Popover
        opened={popoverOpened}
        position="top"
        placement="start"
        withArrow
        styles={{
          popover: { width: "100%" }
        }}

        sx={{
          display: "block !important",
          width: "100%"
        }}
        trapFocus={false}
        transition="pop-top-left"
        onFocusCapture={() => setPopoverOpened(true)}
        onBlurCapture={() => setPopoverOpened(false)}
        target={
          <PasswordInput
            {...props}
            onChange={(event) => {
              const value = event.currentTarget.value;
              setValue(value);
              props.onChange(value);
            }
            }
          />
        }
      >
        <Progress color={color} value={strength} size={5} style={{ marginBottom: 10 }} />
        <PasswordRequirement label="Includes at least 6 characters" meets={value.length > 5} />
        {checks}
      </Popover>
    </Box>
  );
}